import { XCircleIcon, CheckCircleIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'

function MenuMobile({nome, cognome, ruolo, email, initialsImageUrl, handleLogout, handleProfilo}) {
  return (
    <div className="border-t border-gray-200 pb-3 pt-4">
              <div className="flex items-center px-4">
                <div className="flex-shrink-0">
                  <img alt="" src={initialsImageUrl} className="h-10 w-10 rounded-full" />
                </div>
                <div className="ml-3">
                  <div className="text-sm text-gray-800">{nome} {cognome}</div>
                  <div className="text-xs text-gray-500"><strong>Ruolo: </strong>{ruolo}</div>
                </div>
              </div>
              <div className="mt-3 space-y-1">
                  <DisclosureButton
                    key="profilo"
                    as="a"
                    onClick={handleProfilo}
                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                  >
                    Profilo
                  </DisclosureButton>

                  

                  <DisclosureButton
                    key="logout"
                    as="a"
                    onClick={handleLogout}
                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                  >
                    Esci
                  </DisclosureButton>

              </div>
            </div>
  )
}



export {MenuMobile};
