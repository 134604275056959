import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import Dashboard from './pages/Dashboard';
import Recovery_pw from './pages/Recovery_pw';
import Upload from './pages/Upload';
import Profilo from './pages/Profilo';
import Storage from './pages/Storage';
import Utenti from './pages/Utenti';
import { useSelector } from 'react-redux';

function App() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/recovery" element={<Recovery_pw />} />
        <Route
          path="/dashboard"
          element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />}
        />
         <Route
          path="/upload"
          element={isAuthenticated ? <Upload /> : <Navigate to="/login" />}
        />
        <Route
          path="/profilo"
          element={isAuthenticated ? <Profilo /> : <Navigate to="/login" />}
        />
        <Route
          path="/storage"
          element={isAuthenticated ? <Storage /> : <Navigate to="/login" />}
        />
        <Route
          path="/utenti"
          element={isAuthenticated ? <Utenti /> : <Navigate to="/login" />}
        />
        <Route path="*" element={<Navigate to={isAuthenticated ? "/dashboard" : "/login"} />} />
      </Routes>
    </Router>
  );
}

export default App;
