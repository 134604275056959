import { CheckIcon, ChevronUpDownIcon, XCircleIcon, EyeIcon, HomeIcon, UserIcon,  CogIcon, ArrowLeftStartOnRectangleIcon} from '@heroicons/react/20/solid';
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'


function MenuDesktop({nome, cognome, ruolo, email, initialsImageUrl, handleLogout, handleProfilo }) {
  return (
    <Menu as="div" className="relative ml-3">
    <div>
      <MenuButton className="relative flex rounded-full bg-white text-sm focus:outline-none ">
      <div className="flex items-center px-4">
  <div className="flex-shrink-0">
    <img alt="" src={initialsImageUrl} className="h-8 w-8 rounded-full" />
  </div>
  <div className="ml-3">
    <div className="text-sm text-gray-800">{nome} {cognome}</div>
    <div className="text-xs text-gray-500"><strong>Ruolo: </strong>{ruolo}</div>
  </div>
  <button
    type="button"
    className="relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none"
  >
    <span className="absolute -inset-1.5" />
  </button>
</div>
      </MenuButton>
    </div>
    <MenuItems
      transition
      className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
    >
       <MenuItem key="Profilo">
        <button onClick={handleProfilo} className="block px-4 py-2 text-sm text-gray-700">
          <span className="flex items-center">
            <UserIcon className="h-3 w-3 mr-2" aria-hidden="true" /> {/* Posiziona l'icona con margine a destra */}
            Profilo
          </span>
        </button>
      </MenuItem>
        <MenuItem key="Esci">
          <button onClick={handleLogout}  className="block px-4 py-2 text-sm text-gray-700">
          <span className="flex items-center">
            <ArrowLeftStartOnRectangleIcon className="h-3 w-3 mr-2" aria-hidden="true" /> {/* Posiziona l'icona con margine a destra */}
            Esci
          </span>
        </button>
        </MenuItem>                     

    </MenuItems>
  </Menu>
  )
}



export {MenuDesktop};
