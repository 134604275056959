import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../features/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { EnvelopeIcon, PhoneIcon, EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import axios from 'axios';

//Importo Autenticazione Token
import getAuthToken from '../features/auth/axiosSetup';

// Importo Menu 
import { MenuDesktop } from './componets/Menu_desktop';
import { MenuMobile } from './componets/Menu_mobile';
import { MenuNavigazioneMobile } from './componets/Menu_navigazioneMobile';
import { MenuNavigazioneDesktop } from './componets/Menu_navigazioneDesktop';

//Importo Alert
import { AlertRed, AlertGreen } from './componets/Alert';



function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Storage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();



  // State originali
  const [nome, setNome] = useState('');
  const [cognome, setCognome] = useState('');
  const [ruolo, setRuolo] = useState('');
  const [email, setEmail] = useState('');
  const [userCookie, setUser] = useState('');
  const [diskUsage, setDiskUsage] = useState({
    used: 30,
    free: 70,
    total: 100,
    percentage: 30,
  });
  const [textRedAlert, setextRedAlert] = useState('');
  const [textGreenAlert, setextGreenAlert] = useState('');
  const [loadingAlertRed, setloadingAlertRed] = useState(false);
  const [loadingAlertGreen, setloadingAlertGreen] = useState(false);
  const [loadingConf, setloadingConf] = useState(false);
  const [loadingConfNFS, setloadingConfNFS] = useState(false);

  const [typeNFS, settypeNFS] = useState('');
  const [serverNFS, setserverNFS] = useState('');
  const [shareNFS, setshareNFS] = useState('');
  const [snameFolderNFS, setnameFolderNFS] = useState('');
  const [usernameNFS, setusernameNFS] = useState('');
  const [passwordNFS, setpasswordNFS] = useState('');
  const [urlNFS, seturlNFS] = useState('');


  const [typeSFTP, settypeSFTP] = useState('');
  const [hostSFTP, sethostSFTP] = useState('');
  const [usernameSFTP, setusernameSFTP] = useState('');
  const [passwordSFTP, setpasswordSFTP] = useState('');
  const [portSFTP, settportSFTP] = useState('');
  const [urlSFTP, seturlSFTP] = useState('');





  // Funzione per cancellare i Cookie criptati
  function removeEncryptedCookie(key) {
    Cookies.remove(key);
  }

  // Funzione per riprendere i cookie salvati
  function getDecryptedCookie(key) {
    try {
      const encryptedValue = Cookies.get(key);
      if (!encryptedValue) {
        return null;
      }
      const bytes = CryptoJS.AES.decrypt(encryptedValue, process.env.REACT_APP_COOKIE_SECRET);
      const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedValue ? decryptedValue : null;
    } catch (error) {
      return null;
    }
  }

  // Recupero le informazioni dai Cookie
  useEffect(() => {
    setNome(getDecryptedCookie(process.env.REACT_APP_COOKIE_FNAME));
    setCognome(getDecryptedCookie(process.env.REACT_APP_COOKIE_LNAME));
    setRuolo(getDecryptedCookie(process.env.REACT_APP_COOKIE_ROLE));
    setEmail(getDecryptedCookie(process.env.REACT_APP_COOKIE_EMAIL));
    setUser(getDecryptedCookie(process.env.REACT_APP_COOKIE_USERNAME));
  }, []);

  const handleLogout = () => {
    removeEncryptedCookie(process.env.REACT_APP_COOKIE_FNAME);
    removeEncryptedCookie(process.env.REACT_APP_COOKIE_LNAME);
    removeEncryptedCookie(process.env.REACT_APP_COOKIE_ROLE);
    removeEncryptedCookie(process.env.REACT_APP_COOKIE_EMAIL);
    removeEncryptedCookie(process.env.REACT_APP_COOKIE_USERNAME);
    dispatch(logout());
    navigate('/login');
  };

  const handleProfilo = () => {
    navigate('/profilo');
  };

  function generateInitialsImage(name, surname) {
    const initials = (name[0] || '') + (surname[0] || '');
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const size = 80;
    canvas.width = size;
    canvas.height = size;
    context.fillStyle = '#5930B6';
    context.fillRect(0, 0, size, size);
    context.fillStyle = '#fff';
    context.font = '36px Roboto';
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillText(initials, size / 2, size / 2);
    return canvas.toDataURL('image/png');
  }

  const initialsImageUrl = generateInitialsImage(nome, cognome);

  const GetAnno = () => {
    return new Date().getFullYear();
  };

  // Nuovi state per la gestione delle connessioni
  const [connectionType, setConnectionType] = useState('SFTP');
  const [sftpDetails, setSftpDetails] = useState({
    host: '',
    port: '',
    username: '',
    password: '',
    url_pubblico: '',
    userGet: getDecryptedCookie(process.env.REACT_APP_COOKIE_USERNAME),
  });
  const [nfsDetails, setNfsDetails] = useState({
    server: '',
    share: '',
    folderName: '',
    username: '',
    password: '',
    url_pubblico: '',
    userGet: getDecryptedCookie(process.env.REACT_APP_COOKIE_USERNAME),
  });



// Funzione per caricare la connessione salvata
  // Definisci una funzione asincrona all'interno di useEffect
  const loadConnection = async () => {
    try {
      // Ottieni il token in modo asincrono
      const token = await getAuthToken();
      
      // Configura la richiesta
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_URL_API}/settings/get-setup.by-user`,
        headers: { 
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': `Bearer ${token}` // Usa il token ottenuto
        },
        data: new URLSearchParams({
          'usernameGet': getDecryptedCookie(process.env.REACT_APP_COOKIE_USERNAME)
        })
      };
      
      // Effettua la richiesta con axios
      const response = await axios.request(config);

      //Controllo la risposta e conseguneza setto gli state
      if (response.data.status == false) {
      //Nessuna configurazione trovata stampo badge e diabilito configurazione

      setloadingConf(false)
      setloadingConfNFS(false)
      settypeNFS('')
      setserverNFS('')
      setshareNFS('')
      setnameFolderNFS('')
      setusernameNFS('')
      setpasswordNFS('')
      seturlNFS('')
      setConnectionType('SFTP')
      setNfsDetails({ server: '', share: '', folderName: '', username: '', password: '',  url_pubblico: '', userGet: getDecryptedCookie(process.env.REACT_APP_COOKIE_USERNAME) });
      setSftpDetails({ host: '',  port: '', username: '',  password: '', url_pubblico: '', userGet: getDecryptedCookie(process.env.REACT_APP_COOKIE_USERNAME)})
      settypeSFTP('')
      sethostSFTP('')
      setusernameSFTP('')
      setpasswordSFTP('')
      settportSFTP('')
      seturlSFTP('')
      
      
      } else {
       
      //controllo che tipo di connsessione è 
      
      if (response.data.data.type === 'SFTP') {
      
      //Connessione SFTP Visualizzo  
      //Imposto gli state 
      setloadingConf(true)
      settypeSFTP(response.data.data.type)
      sethostSFTP(response.data.data.server)
      setusernameSFTP(response.data.data.username)
      setpasswordSFTP(response.data.data.password)
      settportSFTP(response.data.data.port)
      seturlSFTP(response.data.data.url_pubblico)

      setConnectionType('SFTP')
      setSftpDetails({ host: response.data.data.server,  port: response.data.data.port, username: response.data.data.username,  password: response.data.data.password, url_pubblico: response.data.data.url_pubblico, userGet: getDecryptedCookie(process.env.REACT_APP_COOKIE_USERNAME)})
      


      //Fine Sftp

      } else {
      
      //Connessione NFS
      //Imposto gli state 
      setloadingConf(true)
      setloadingConfNFS(true)
      settypeNFS(response.data.data.type)
      setserverNFS(response.data.data.server)
      setshareNFS(response.data.data.share)
      setnameFolderNFS(response.data.data.folderName)
      setusernameNFS(response.data.data.username)
      setpasswordNFS(response.data.data.password)
      seturlNFS(response.data.data.url_pubblico)
      
      //Utilizzo Disco

      setDiskUsage({
        used: response.data.diskUsage.usato,
        free: response.data.diskUsage.libero,
        total: response.data.diskUsage.totale,
        percentage: response.data.diskUsage.percentage,
      })

      setConnectionType('NFS')
      setNfsDetails({ server: response.data.data.server, share: response.data.data.share, folderName: response.data.data.folderName, username: response.data.data.username, password: response.data.data.password,  url_pubblico: response.data.data.url_pubblico, userGet: getDecryptedCookie(process.env.REACT_APP_COOKIE_USERNAME) });

      }

      }





    } catch (error) {
      console.log('Errore nella richiesta:', error);
    }
  };


//Funzione per cancellare la connesione
const deleteConnection = async () => {
  try {
    // Ottieni il token in modo asincrono
    const token = await getAuthToken();
    
    // Configura la richiesta
    let config = {
      method: 'delete',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_URL_API}/settings/delete-connection`,
      headers: { 
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${token}` // Usa il token ottenuto
      },
      data: new URLSearchParams({
        'usernameGet': getDecryptedCookie(process.env.REACT_APP_COOKIE_USERNAME)
      })
    };
    
    // Effettua la richiesta con axios
    const response = await axios.request(config);

    console.log(response.data)

    //Controllo la risposta e di conseguenza imposto gli state

    if (response.data.status == false) {

    //Errore
    setextRedAlert('Attenzione! Si è verificato un errore nella cancellazione della connessione, si prega di riporvare.');
        setloadingAlertRed(true);
        setTimeout(() => {
          setloadingAlertRed(false);
          setextRedAlert('');
        }, 4000);
    
      
    } else {
    
    //Success
    setextGreenAlert('Storage cancellato con successo!');
    setloadingAlertGreen(true);

          setTimeout(() => {
            setloadingAlertGreen(false);
            setextGreenAlert('');
          }, 4000);
  
    await loadConnection();

    }

  } catch (error) {
    console.log('Errore nella richiesta:', error);
  }
};

useEffect(() => {
  // Chiama la funzione per caricare la connessione
  loadConnection();
}, []); // Assicurati che l'array di dipendenze sia vuoto se vuoi eseguire il codice solo una volta

  // Funzione per verificare se le proprietà sono vuote
    function areDetailsEmpty(details) {
      return !details.host || !details.port || !details.username || !details.password || !details.url_pubblico;
    }

    function areNfsDetailsEmpty(details) {
      return !details.server || !details.share || !details.folderName || !details.username || !details.password || !details.url_pubblico;
    }

    
  // Funzione per salvare la connessione
  const handleSaveConnection = async () => {
    const data = connectionType === 'SFTP' ? sftpDetails : nfsDetails;
  
    if (connectionType === 'SFTP') {
      if (areDetailsEmpty(sftpDetails)) {
        console.log("Almeno uno dei campi SFTP è vuoto.");
        setextRedAlert('Attenzione! I campi non possono essere vuoti, si prega di riprovare.');
        setloadingAlertRed(true);
        setTimeout(() => {
          setloadingAlertRed(false);
          setextRedAlert('');
        }, 2000);
      } else {
        console.log("Tutti i campi SFTP sono valorizzati.");
        // Procedo a creare lo storage SFTP
        // Inserisci qui il codice per gestire SFTP

        try {
          // Ottieni il token in modo asincrono
          const token = await getAuthToken();
  
          // Procedo a creare lo storage NFS
          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_URL_API}/settings/add-sftp-connection`,
            headers: {
              'authorization': `Bearer ${token}`, // Usa il token risolto
            }, data: sftpDetails
          };
  
          // Effettua la richiesta con axios in modo asincrono
          const response = await axios.request(config);

          //Controllo la risposta
          if (response.data.status == true) {
          //Stampo messaggio di successo
          setextGreenAlert('Storage collegato con successo!');
          setloadingAlertGreen(true);

          setTimeout(() => {
            setloadingAlertGreen(false);
            setextGreenAlert('');
          }, 3000);

          //Ricarico la connessione
          loadConnection();

          //
          } else {
          //Stampo Errore
          setextRedAlert(response.data.message);
          setloadingAlertRed(true);
          setTimeout(() => {
          setloadingAlertRed(false);
          setextRedAlert('');
           }, 4000);
          

          //
          }
        } catch (error) {
          console.error("Errore durante la creazione dello storage NFS:", error);
        }







        //Fine inserimento dati SFTP
      }
    } else if (connectionType === 'NFS') {
      if (areNfsDetailsEmpty(nfsDetails)) {
        console.log("Almeno uno dei campi NFS è vuoto.");
        setextRedAlert('Attenzione! I campi non possono essere vuoti, si prega di riprovare.');
        setloadingAlertRed(true);
        setTimeout(() => {
          setloadingAlertRed(false);
          setextRedAlert('');
        }, 2000);
      } else {
        console.log("Tutti i campi NFS sono valorizzati.");
  
        try {
          // Ottieni il token in modo asincrono
          const token = await getAuthToken();
  
          // Procedo a creare lo storage NFS
          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_URL_API}/settings/setup-and-disk-usage-nfs`,
            headers: {
              'authorization': `Bearer ${token}`, // Usa il token risolto
            }, data: nfsDetails
          };
  
          // Effettua la richiesta con axios in modo asincrono
          const response = await axios.request(config);

          //Controllo la risposta
          if (response.data.status == true) {
          //Stampo messaggio di successo
          setextGreenAlert('Storage collegato con successo!');
          setloadingAlertGreen(true);

          setTimeout(() => {
            setloadingAlertGreen(false);
            setextGreenAlert('');
          }, 3000);

          //Ricarico la connessione
          loadConnection();

          //
          } else {
          //Stampo Errore
          setextRedAlert(response.data.error);
          setloadingAlertRed(true);
          setTimeout(() => {
          setloadingAlertRed(false);
          setextRedAlert('');
           }, 4000);
          

          //
          }
        } catch (error) {
          console.error("Errore durante la creazione dello storage NFS:", error);
        }
      }
    } else {
      console.log("Tipo di connessione non riconosciuto.");
    }
  };
  
  
 

  // Funzione per eliminare la connessione
  const handleDeleteConnection = () => {
    deleteConnection()
  };

  return (
    <>
      <div className="flex flex-col min-h-screen bg-gray-100">
        <Disclosure as="nav" className="bg-white shadow-sm">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="flex flex-shrink-0 items-center">
                  <img
                    alt="Your Company"
                    src="https://fileserver.centrodelsorriso.tribusinformatica.it/logo_top.png"
                    className="block h-8 w-auto lg:hidden"
                  />
                  <img
                    alt="Your Company"
                    src="https://fileserver.centrodelsorriso.tribusinformatica.it/logo_top.png"
                    className="hidden h-8 w-auto lg:block"
                  />
                </div>
                <MenuNavigazioneDesktop ruolo={ruolo} navigate={navigate} />
              </div>

              <div className="hidden sm:ml-6 sm:flex sm:items-center">
                <MenuDesktop nome={nome} cognome={cognome} ruolo={ruolo} email={email} initialsImageUrl={initialsImageUrl} handleLogout={handleLogout} handleProfilo={handleProfilo} />
              </div>

              <div className="-mr-2 flex items-center sm:hidden">
                <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden" />
                  <XMarkIcon aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block" />
                </DisclosureButton>
              </div>
            </div>
          </div>

          <DisclosurePanel className="sm:hidden">
            <MenuNavigazioneMobile ruolo={ruolo} navigate={navigate} />
            <MenuMobile nome={nome} cognome={cognome} ruolo={ruolo} email={email} initialsImageUrl={initialsImageUrl} handleLogout={handleLogout} handleProfilo={handleProfilo} />
          </DisclosurePanel>
        </Disclosure>

        <div className="flex-grow py-10">
          <header>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 bg-white shadow sm:rounded-lg p-8">
              <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">Storage</h1>
              <div className='mt-2'>
                <div className="px-4 sm:px-0">
                  <h3 className="text-base font-semibold leading-7 text-gray-900">Informazioni</h3>
                  <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Di seguito sono riportate le configurazioni dello storage.</p>
                </div>
             {loadingConf ? (
                loadingConfNFS ? (
                      <dl className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium leading-6 text-gray-900">Tipo di Storage</dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700">
                            <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                             {typeNFS}
                            </span>
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium leading-6 text-gray-900">Server</dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700">
                            <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                            {serverNFS}
                            </span>
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium leading-6 text-gray-900">Share (Cartella di destinazione su Server Nas)</dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700">
                            <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                            {shareNFS}
                            </span>
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium leading-6 text-gray-900">Nome Cartella</dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700">
                            <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                            {snameFolderNFS}
                            </span>
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium leading-6 text-gray-900">Username</dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700">
                            <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                              {usernameNFS}
                            </span>
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium leading-6 text-gray-900">Password</dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700">
                            <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                              {passwordNFS}
                            </span>
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium leading-6 text-gray-900">Url Pubblico puntamento Esterno NAS</dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700">
                            <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                            {urlNFS}
                            </span>
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium leading-6 text-gray-900">Utilizzo del Disco</dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700">
                            <div className="w-1/2 bg-gray-200 rounded-full h-4">
                              <div
                                className="bg-green-600 h-4 rounded-full"
                                style={{ width: `${diskUsage.percentage}%` }}
                              />
                            </div>
                            <p className="mt-2 text-xs text-gray-500">{`Utilizzato: ${diskUsage.used} / ${diskUsage.total} (${diskUsage.percentage}%)`}</p>
                          </dd>
                        </div>
                      </dl>
                    ) : (
                      <dl className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Tipo di Storage</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700">
                          <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                           {typeSFTP}
                          </span>
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Host</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700">
                          <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                          {hostSFTP}
                          </span>
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Porta di collegamento</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700">
                          <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                          {portSFTP}
                          </span>
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Username</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700">
                          <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                            {usernameSFTP}
                          </span>
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Password</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700">
                          <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                            {passwordSFTP}
                          </span>
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Url Pubblico puntamento Esterno NAS</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700">
                          <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                          {urlSFTP}
                          </span>
                        </dd>
                      </div>
                    </dl>
                    )
                  ) : (
                    <span className="mt-2 inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                      Attenzione! Nessun storage configurato si prega di aggiungerne uno per il corretto funzionamento dell'applicazione.
                    </span>
                  )}

              </div>
            </div>
          </header>

          <main>
            <div className="mt-4 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 bg-white shadow sm:rounded-lg p-8">
              <div className="bg-white  sm:rounded-lg">
                <h3 className="text-base font-semibold leading-7 text-gray-900">Gestione Connessione</h3>

                <div className="mt-4">
                  <label htmlFor="connectionType" className="block text-sm font-medium text-gray-700">Tipo di Connessione</label>
                  <select
                    id="connectionType"
                    value={connectionType}
                    onChange={(e) => setConnectionType(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  >
                    <option value="SFTP">SFTP</option>
                    <option value="NFS">NFS/CIFS/SMB</option>
                  </select>
                </div>

                {loadingAlertRed ? <AlertRed testo={textRedAlert} /> : null}
                {loadingAlertGreen ? <AlertGreen testo={textGreenAlert} /> : null}

                {connectionType === 'SFTP' && (
                  <div className="mt-4">
                    <h4 className="text-sm font-medium leading-7 text-gray-900">Dettagli SFTP</h4>
                    <small>Attenzione: Verra creata una cartella denominata fotografie nella destinazione </small>
                    <div className="mt-2">
                      <label htmlFor="sftp-host" className="block text-sm font-medium text-gray-700">Host</label>
                      <input
                        type="text"
                        id="sftp-host"
                        value={sftpDetails.host}
                        onChange={(e) => setSftpDetails({ ...sftpDetails, host: e.target.value })}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div className="mt-2">
                      <label htmlFor="sftp-port" className="block text-sm font-medium text-gray-700">Porta</label>
                      <input
                        type="text"
                        id="sftp-port"
                        value={sftpDetails.port}
                        onChange={(e) => setSftpDetails({ ...sftpDetails, port: e.target.value })}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div className="mt-2">
                      <label htmlFor="sftp-username" className="block text-sm font-medium text-gray-700">Username</label>
                      <input
                        type="text"
                        id="sftp-username"
                        value={sftpDetails.username}
                        onChange={(e) => setSftpDetails({ ...sftpDetails, username: e.target.value })}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div className="mt-2">
                      <label htmlFor="sftp-password" className="block text-sm font-medium text-gray-700">Password</label>
                      <input
                        type="password"
                        id="sftp-password"
                        value={sftpDetails.password}
                        onChange={(e) => setSftpDetails({ ...sftpDetails, password: e.target.value })}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div className="mt-2">
                      <label htmlFor="sftp-url_pubblico" className="block text-sm font-medium text-gray-700">Url Pubblico (Compreso https://)</label>
                      <input
                        type="text"
                        id="sftp-url_pubblico"
                        value={sftpDetails.url_pubblico}
                        onChange={(e) => setSftpDetails({ ...sftpDetails, url_pubblico: e.target.value })}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                )}

                {connectionType === 'NFS' && (
                  <div className="mt-4">
                    <h4 className="text-sm font-medium leading-7 text-gray-900">Dettagli NFS</h4>
                    <div className="mt-2">
                      <label htmlFor="nfs-server" className="block text-sm font-medium text-gray-700">Server</label>
                      <input
                        type="text"
                        id="nfs-server"
                        value={nfsDetails.server}
                        onChange={(e) => setNfsDetails({ ...nfsDetails, server: e.target.value })}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div className="mt-2">
                      <label htmlFor="nfs-share" className="block text-sm font-medium text-gray-700">Share (Cartella di destinazione su Server Nas) </label>
                      <input
                        type="text"
                        id="nfs-share"
                        value={nfsDetails.share}
                        onChange={(e) => setNfsDetails({ ...nfsDetails, share: e.target.value })}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div className="mt-2">
                      <label htmlFor="nfs-folderName" className="block text-sm font-medium text-gray-700">Nome Cartella (Verrà creata nella destinazione Share Scelta)</label>
                      <input
                        type="text"
                        id="nfs-folderName"
                        value={nfsDetails.folderName}
                        onChange={(e) => setNfsDetails({ ...nfsDetails, folderName: e.target.value })}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div className="mt-2">
                      <label htmlFor="nfs-username" className="block text-sm font-medium text-gray-700">Username</label>
                      <input
                        type="text"
                        id="nfs-username"
                        value={nfsDetails.username}
                        onChange={(e) => setNfsDetails({ ...nfsDetails, username: e.target.value })}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div className="mt-2">
                      <label htmlFor="nfs-password" className="block text-sm font-medium text-gray-700">Password</label>
                      <input
                        type="password"
                        id="nfs-password"
                        value={nfsDetails.password}
                        onChange={(e) => setNfsDetails({ ...nfsDetails, password: e.target.value })}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div className="mt-2">
                      <label htmlFor="nfs-url_pubblico" className="block text-sm font-medium text-gray-700">Url Pubblico (Compreso https://)</label>
                      <input
                        type="text"
                        id="nfs-url_pubblico"
                        value={nfsDetails.url_pubblico}
                        onChange={(e) => setNfsDetails({ ...nfsDetails, url_pubblico: e.target.value })}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
              
                  </div>
                )}

                <div className="mt-6 flex space-x-4">
                  <button
                    type="button"
                    onClick={handleSaveConnection}
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Salva Connessione
                  </button>

                  { loadingConf ? <button
                    type="button"
                    onClick={handleDeleteConnection}
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    Elimina Connessione
                  </button> : null }
                </div>
              </div>
            </div>
          </main>
        </div>

        <footer className="bg-white">
          <div className="mx-auto px-6 sm:py-3 md:py-3 xl:py-5 items-center">
            <div className="mt-4 md:order-1 md:mt-0">
              <p className="text-center text-xs leading-5 text-gray-500">
                {GetAnno()} © Centro del Sorriso, sviluppato con il ❤️ da Tribus Informatica Srl
              </p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default Storage;
