// File per Voci Menu
import { CheckIcon, ChevronUpDownIcon, XCircleIcon, EyeIcon, HomeIcon, ArrowUpOnSquareIcon, UsersIcon, PresentationChartBarIcon, AdjustmentsHorizontalIcon } from '@heroicons/react/20/solid';
import { CircleStackIcon } from '@heroicons/react/24/outline';

// Definizione dell'array di navigazione
const navigazione = [
  { name: 'Dashboard', href: 'dashboard', current: true, permessi: 'user', icon: HomeIcon },
  { name: 'Pazienti', href: 'pazienti', current: false, permessi: 'user', icon: UsersIcon },
  { name: 'Upload', href: 'upload', current: false, permessi: 'user' , icon: ArrowUpOnSquareIcon },
  { name: 'Presenta', href: 'presenta', current: false, permessi: 'user', icon: PresentationChartBarIcon},
  { name: 'Storage', href: 'storage', current: false, permessi: 'admin', icon: CircleStackIcon },
  { name: 'Utenti', href: 'utenti', current: false, permessi: 'admin', icon: AdjustmentsHorizontalIcon },
];

// Esportazione dell'array per utilizzarlo in altri file
export default navigazione;
