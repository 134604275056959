import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../features/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { PaperClipIcon, EyeSlashIcon, EyeIcon  } from '@heroicons/react/20/solid'

import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

import axios from 'axios';


//Importo Menu 
import { MenuDesktop } from './componets/Menu_desktop'
import { MenuMobile } from './componets/Menu_mobile';
import { MenuNavigazioneMobile } from './componets/Menu_navigazioneMobile';
import { MenuNavigazioneDesktop } from './componets/Menu_navigazioneDesktop';

//Importo Alert
import { AlertRed, AlertGreen } from './componets/Alert';


function classNames(...classes) {
return classes.filter(Boolean).join(' ')
}

function Profilo() {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  //Dichiaro gli State
  const [nome, setNome ] = useState('');
  const [cognome, setCognome ] = useState('');
  const [ruolo, setRuolo ] = useState('');
  const [email, setEmail ] = useState('');
  const [username, setUsername ] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [textRedAlert, setextRedAlert] = useState('');
  const [textGreenAlert, setextGreenAlert] = useState('');
  const [loadingAlertRed, setloadingAlertRed] = useState(false);
  const [loadingAlertGreen, setloadingAlertGreen] = useState(false);



  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  // Funzione per cancellare i Cookie criptati
function removeEncryptedCookie(key) {
  // Rimuove il cookie specificato
  Cookies.remove(key);
}
   // Funzione per riprendere i cookie salvati
  function getDecryptedCookie(key) {
    try {
      const encryptedValue = Cookies.get(key);
  
      if (!encryptedValue) {
        return null;
      }
      // Decriptazione
      const bytes = CryptoJS.AES.decrypt(encryptedValue, process.env.REACT_APP_COOKIE_SECRET);
  
      if (bytes) {
        const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
  
        if (decryptedValue) {
          return decryptedValue;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  // Recupero le informazioni dai Cookie
  // Esegui il controllo una sola volta quando la pagina viene montata
   useEffect(() => {
    setNome(getDecryptedCookie(process.env.REACT_APP_COOKIE_FNAME))
    setCognome(getDecryptedCookie(process.env.REACT_APP_COOKIE_LNAME))
    setRuolo(getDecryptedCookie(process.env.REACT_APP_COOKIE_ROLE))
    setEmail(getDecryptedCookie(process.env.REACT_APP_COOKIE_EMAIL))
    setUsername(getDecryptedCookie(process.env.REACT_APP_COOKIE_USERNAME))
  }, []);

  const handleLogout = () => {

    //Cancello i Cookiee 
    removeEncryptedCookie(process.env.REACT_APP_COOKIE_FNAME);
    removeEncryptedCookie(process.env.REACT_APP_COOKIE_LNAME);
    removeEncryptedCookie(process.env.REACT_APP_COOKIE_ROLE);
    removeEncryptedCookie(process.env.REACT_APP_COOKIE_EMAIL);
    removeEncryptedCookie(process.env.REACT_APP_COOKIE_USERNAME);


    //Eseguo Logout
    dispatch(logout());
    navigate('/login');
  };

  const handleProfilo = () => {
    navigate('/profilo');
  }
  

  //Eseguo Update della password
  const handleReset = async () => {

    if (!password) {
      setextRedAlert('Attenzione! Il campo password non può essere vuoto, si prega di riprovare.');
      setloadingAlertRed(true);
      setTimeout(() => {
        setloadingAlertRed(false);
        setextRedAlert('');
      }, 4000);
    } else {
      try {
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_URL_API}/users/forgot_password_web`,
          headers: { 
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          data: {
            'username': username,
            'password': password,
          }
        };

        const response = await axios.request(config);

        if (response.data.status === true) {
          setextGreenAlert('Congratulazioni! Password modificata con successo.');
          setloadingAlertGreen(true);

          setTimeout(() => {
            setloadingAlertGreen(false);
            setextGreenAlert('');
          }, 4000);


          setPassword('');
        } else {
          setextRedAlert('Attenzione! Errore nell\'aggionamento della password si prega di contattare l\'amministratore di sistema.');
          setloadingAlertRed(true);
          setTimeout(() => {
            setloadingAlertRed(false);
            setextRedAlert('');
          }, 4000);
        }

        return response.data;
      } catch (error) {
        console.error('Error during login:', error);
        throw error;
      }
    }
  };

  //Funzione per Generare l'immagine
  function generateInitialsImage(name, surname) {
    const initials = (name[0] || '') + (surname[0] || '');
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    
    // Imposta le dimensioni del canvas
    const size = 80; // Dimensione dell'immagine
    canvas.width = size;
    canvas.height = size;
  
    // Imposta il colore di sfondo
    context.fillStyle = '#5930B6'; // Colore di sfondo (puoi personalizzarlo)
    context.fillRect(0, 0, size, size);
  
    // Imposta le proprietà del testo
    context.fillStyle = '#fff'; // Colore del testo
    context.font = '36px Roboto'; // Font e dimensione del testo

    context.textAlign = 'center';
    context.textBaseline = 'middle';
  
    // Disegna le iniziali al centro del canvas
    context.fillText(initials, size / 2, size / 2);
  
    // Ritorna l'URL dell'immagine generata
    return canvas.toDataURL('image/png');
  }
  

  // Genera l'immagine con le iniziali dell'utente
  const initialsImageUrl = generateInitialsImage(nome, cognome);

  const GetAnno = () => {
    return new Date().getFullYear();
  }
  return (
    <>
      <div className="flex flex-col min-h-screen bg-gray-100">
        <Disclosure as="nav" className="bg-white shadow-sm">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="flex flex-shrink-0 items-center">
                  <img
                    alt="Your Company"
                    src="https://fileserver.centrodelsorriso.tribusinformatica.it/logo_top.png"
                    className="block h-8 w-auto lg:hidden"
                  />
                  <img
                    alt="Your Company"
                    src="https://fileserver.centrodelsorriso.tribusinformatica.it/logo_top.png"
                    className="hidden h-8 w-auto lg:block"
                  />
                </div>
                <MenuNavigazioneDesktop ruolo={ruolo} navigate={navigate} />
              </div>


              <div className="hidden sm:ml-6 sm:flex sm:items-center">
                {/* Profile dropdown */}
                <MenuDesktop nome={nome} cognome={cognome} ruolo={ruolo} email={email} initialsImageUrl={initialsImageUrl}  handleLogout={handleLogout}  handleProfilo={handleProfilo} />
              </div>

              {/* Mobile Section */}
              <div className="-mr-2 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden" />
                  <XMarkIcon aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block" />
                </DisclosureButton>
              </div>
            </div>
          </div>
           
        {/* Mobile  */}
          <DisclosurePanel className="sm:hidden">
           <MenuNavigazioneMobile ruolo={ruolo} navigate={navigate} />
            <MenuMobile nome={nome} cognome={cognome} ruolo={ruolo} email={email} initialsImageUrl={initialsImageUrl}  handleLogout={handleLogout}  handleProfilo={handleProfilo} />
          </DisclosurePanel>
        </Disclosure>

     <div className="flex-grow py-10 ">
          <header>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 bg-white shadow sm:rounded-lg p-5">
              <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">Profilo</h1>

              <div className='mt-2'>
              <div>
      <div className="px-4 sm:px-0 ">
        <h3 className="text-base font-semibold leading-7 text-gray-900">Informazioni</h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Di seguito sono elencate tutte le voci associate a questo account.</p>
      </div>
      <div className="mt-6">
        <dl className="grid grid-cols-1 sm:grid-cols-2">
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Nome e Cognome</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{nome} {cognome}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Ruolo</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{ruolo}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Indirizzo Email</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{email}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Nome Utente</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{username}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-2 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Permessi</dt>
            {ruolo == 'admin' ? <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
              Gli utenti con ruolo di Admin possiedono pieni poteri e privilegi all'interno del sistema, inclusi l'accesso completo a tutte le funzionalità e la capacità di gestire risorse e impostazioni senza restrizioni.
            </dd>: <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
            Gli utenti con ruolo di User hanno accesso limitato alle funzionalità del sistema, con restrizioni specifiche alle aree e alle operazioni. Questo ruolo consente di interagire con le risorse e le funzioni essenziali, garantendo un uso sicuro e conforme alle autorizzazioni assegnate.
            </dd>}
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-2 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Password</dt>
            <dt className="text-xs font-medium leading-6 text-gray-500">Inserisci la nuova password e clicca su "Modifica Password"</dt>
            <dd className="mt-2 text-sm text-gray-900">
              

              <div>
                <div className="space-y-4">
              <div>
                   
              <div>
              
              <div className="mt-1 relative">
                <input
                  onChange={handlePasswordChange}
                  value={password}
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"} // Mostra/nasconde la password
                  autoComplete="current-password"
                  className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <span
                  onClick={togglePasswordVisibility}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer text-gray-500"
                >
                  {showPassword ? (
                    <EyeSlashIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  ) : (
                    <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  )}
                </span>
              </div>
            </div>

                
                  <div>
                   <button
                      onClick={() => handleReset()}
                      className="flex mt-2 justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Modifica Password
                    </button> 


                    {loadingAlertRed ? <AlertRed testo={textRedAlert} /> : null}
            {loadingAlertGreen ? <AlertGreen testo={textGreenAlert} /> : null}

                  </div>
                </div>
              </div>
            </div>


            </dd>
          </div>
        </dl>
      </div>
    </div>
   
    </div>


            </div>
          </header>
          <main>
            <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">{/* Your content */}</div>
          </main>
        </div>

        <footer className="bg-white">
          <div className="mx-auto px-6 sm:py-3 md:py-3 xl:py-5 items-center">
            <div className="mt-4 md:order-1 md:mt-0">
              <p className="text-center text-xs leading-5 text-gray-500">
                {GetAnno()} © Centro del Sorriso, sviluppato con il ❤️ da Tribus Informatica Srl
              </p>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}


export default Profilo;
