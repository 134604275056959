import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../features/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { EnvelopeIcon, PhoneIcon, EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

//Importo Menu 
import { MenuDesktop } from './componets/Menu_desktop'
import { MenuMobile } from './componets/Menu_mobile';
import { MenuNavigazioneMobile } from './componets/Menu_navigazioneMobile';
import { MenuNavigazioneDesktop } from './componets/Menu_navigazioneDesktop';


function classNames(...classes) {
return classes.filter(Boolean).join(' ')
}

const statuses = {
  Paid: 'text-green-700 bg-green-50 ring-green-600/20',
  Withdraw: 'text-gray-600 bg-gray-50 ring-gray-500/10',
  Overdue: 'text-red-700 bg-red-50 ring-red-600/10',
}
const clients = [
  {
    id: 1,
    name: 'Pazienti',
    imageUrl: `${process.env.REACT_APP_URL_FILESERVER}/medical.svg`,
    lastInvoice: {  amount: '1500'},
  },
  {
    id: 2,
    name: 'Fotografie',
    imageUrl: `${process.env.REACT_APP_URL_FILESERVER}/image-gallery.svg`,
    lastInvoice: { amount: '500'},
  },
  {
    id: 3,
    name: 'Caricamenti',
    imageUrl: `${process.env.REACT_APP_URL_FILESERVER}/file.svg`,
    lastInvoice: {  amount: '25'},
  },
  {
    id: 3,
    name: 'Presentazione',
    imageUrl: `${process.env.REACT_APP_URL_FILESERVER}/presentation.svg`,
    lastInvoice: {amount: '120'},
  },
  {
    id: 3,
    name: 'Elaborazioni AI',
    imageUrl: `${process.env.REACT_APP_URL_FILESERVER}/ai.svg`,
    lastInvoice: {amount: '2000'},
  },
  {
    id: 3,
    name: 'Gestione Utenti',
    imageUrl: `${process.env.REACT_APP_URL_FILESERVER}/users.svg`,
    lastInvoice: { amount: '10'},
  },
]

function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  //Dichiaro gli State
  const [nome, setNome ] = useState('');
  const [cognome, setCognome ] = useState('');
  const [ruolo, setRuolo ] = useState('');
  const [email, setEmail ] = useState('');



  // Funzione per cancellare i Cookie criptati
function removeEncryptedCookie(key) {
  // Rimuove il cookie specificato
  Cookies.remove(key);
}
   // Funzione per riprendere i cookie salvati
  function getDecryptedCookie(key) {
    try {
      const encryptedValue = Cookies.get(key);
  
      if (!encryptedValue) {
        return null;
      }
      // Decriptazione
      const bytes = CryptoJS.AES.decrypt(encryptedValue, process.env.REACT_APP_COOKIE_SECRET);
  
      if (bytes) {
        const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
  
        if (decryptedValue) {
          return decryptedValue;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  // Recupero le informazioni dai Cookie
  // Esegui il controllo una sola volta quando la pagina viene montata
   useEffect(() => {
    setNome(getDecryptedCookie(process.env.REACT_APP_COOKIE_FNAME))
    setCognome(getDecryptedCookie(process.env.REACT_APP_COOKIE_LNAME))
    setRuolo(getDecryptedCookie(process.env.REACT_APP_COOKIE_ROLE))
    setEmail(getDecryptedCookie(process.env.REACT_APP_COOKIE_EMAIL))
  }, []);

  const handleLogout = () => {

    //Cancello i Cookiee 
    removeEncryptedCookie(process.env.REACT_APP_COOKIE_FNAME);
    removeEncryptedCookie(process.env.REACT_APP_COOKIE_LNAME);
    removeEncryptedCookie(process.env.REACT_APP_COOKIE_ROLE);
    removeEncryptedCookie(process.env.REACT_APP_COOKIE_EMAIL);
    removeEncryptedCookie(process.env.REACT_APP_COOKIE_USERNAME);

    //Eseguo Logout
    dispatch(logout());
    navigate('/login');
  };

  const handleProfilo = () => {
    navigate('/profilo');
  }

  //Funzione per Generare l'immagine
  function generateInitialsImage(name, surname) {
    const initials = (name[0] || '') + (surname[0] || '');
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    
    // Imposta le dimensioni del canvas
    const size = 80; // Dimensione dell'immagine
    canvas.width = size;
    canvas.height = size;
  
    // Imposta il colore di sfondo
    context.fillStyle = '#5930B6'; // Colore di sfondo (puoi personalizzarlo)
    context.fillRect(0, 0, size, size);
  
    // Imposta le proprietà del testo
    context.fillStyle = '#fff'; // Colore del testo
    context.font = '36px Roboto'; // Font e dimensione del testo

    context.textAlign = 'center';
    context.textBaseline = 'middle';
  
    // Disegna le iniziali al centro del canvas
    context.fillText(initials, size / 2, size / 2);
  
    // Ritorna l'URL dell'immagine generata
    return canvas.toDataURL('image/png');
  }
  

  // Genera l'immagine con le iniziali dell'utente
  const initialsImageUrl = generateInitialsImage(nome, cognome);

  const GetAnno = () => {
    return new Date().getFullYear();
  }
  return (
    <>
      <div className="flex flex-col min-h-screen bg-gray-100">
        <Disclosure as="nav" className="bg-white shadow-sm">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="flex flex-shrink-0 items-center">
                  <img
                    alt="Your Company"
                    src="https://fileserver.centrodelsorriso.tribusinformatica.it/logo_top.png"
                    className="block h-8 w-auto lg:hidden"
                  />
                  <img
                    alt="Your Company"
                    src="https://fileserver.centrodelsorriso.tribusinformatica.it/logo_top.png"
                    className="hidden h-8 w-auto lg:block"
                  />
                </div>
                <MenuNavigazioneDesktop ruolo={ruolo} navigate={navigate} />
              </div>


              <div className="hidden sm:ml-6 sm:flex sm:items-center">
                {/* Profile dropdown */}
                <MenuDesktop nome={nome} cognome={cognome} ruolo={ruolo} email={email} initialsImageUrl={initialsImageUrl}  handleLogout={handleLogout}  handleProfilo={handleProfilo} />
              </div>

              {/* Mobile Section */}
              <div className="-mr-2 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden" />
                  <XMarkIcon aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block" />
                </DisclosureButton>
              </div>
            </div>
          </div>
           
        {/* Mobile  */}
          <DisclosurePanel className="sm:hidden">
           <MenuNavigazioneMobile ruolo={ruolo} navigate={navigate} />
            <MenuMobile nome={nome} cognome={cognome} ruolo={ruolo} email={email} initialsImageUrl={initialsImageUrl}  handleLogout={handleLogout}  handleProfilo={handleProfilo} />
          </DisclosurePanel>
        </Disclosure>

      <div className="flex-grow py-10 ">
      <header>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 bg-white shadow sm:rounded-lg p-8">
      <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">Dashboard</h1>

      <div className='mt-2'>
      <div>
      <div className="px-4 sm:px-0 ">
      </div>
    
      
      <ul role="list" className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
      {clients.map((client) => (
        <li key={client.id} className="overflow-hidden rounded-xl border border-gray-200">
          <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
            <img
              alt={client.name}
              src={client.imageUrl}
              className="h-12 w-12 flex-none rounded-lg bg-white object-cover p-1 ring-1 ring-gray-900/10"
            />
            <div className="text-sm font-medium leading-6 text-gray-900">{client.name}</div>
            <Menu as="div" className="relative ml-auto">
              <MenuButton className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                <span className="sr-only">Open options</span>
                <EllipsisHorizontalIcon aria-hidden="true" className="h-5 w-5" />
              </MenuButton>
              <MenuItems
                transition
                className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <MenuItem>
                  <a href="#" className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50">
                    Vedi<span className="sr-only">, {client.name}</span>
                  </a>
                </MenuItem>
              </MenuItems>
            </Menu>
          </div>
          <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
            <div className="flex justify-between gap-x-4 py-3">
              <dd className="flex items-start gap-x-2">
                <div className="font-medium text-gray-900">{client.lastInvoice.amount}</div>
              </dd>
            </div>
          </dl>
        </li>
      ))}
    </ul>


    </div>
   
    </div>


            </div>
          </header>
          <main>
            <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">{/* Your content */}</div>
          </main>
        </div>

        <footer className="bg-white">
          <div className="mx-auto px-6 sm:py-3 md:py-3 xl:py-5 items-center">
            <div className="mt-4 md:order-1 md:mt-0">
              <p className="text-center text-xs leading-5 text-gray-500">
                {GetAnno()} © Centro del Sorriso, sviluppato con il ❤️ da Tribus Informatica Srl
              </p>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}


export default Dashboard;
