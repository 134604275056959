import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { login } from '../features/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon, XCircleIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import axios from 'axios';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { ThreeCircles } from 'react-loader-spinner';

//Importo Alert
import { AlertRed, AlertGreen } from './componets/Alert';

function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const lingue = [
    {
      id: 1,
      name: 'Italiano',
      avatar: `${process.env.REACT_APP_URL_FILESERVER}/flag/it.png`,
    }
  ];

  // Dichiaro le Variabili 
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [selected, setSelected] = useState(lingue[0]);
  const [textRedAlert, setextRedAlert] = useState('');
  const [textGreenAlert, setextGreenAlert] = useState('');
  const [loadingAlertRed, setloadingAlertRed] = useState(false);
  const [loadingAlertGreen, setloadingAlertGreen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loadingSpinner, setloadingSpinner] = useState(false);

  // Funzione per Criptare la Stringa
  function saveEncryptedCookie(key, value) {
    const encryptedValue = CryptoJS.AES.encrypt(value, process.env.REACT_APP_COOKIE_SECRET).toString();
    Cookies.set(key, encryptedValue, { expires: 1000 }); // Imposta un cookie che scade in 1 giorno
  }

  // Funzione per riprendere i cookie salvati
  function getDecryptedCookie(key) {
    const encryptedValue = Cookies.get(key);
    if (encryptedValue) {
      const bytes = CryptoJS.AES.decrypt(encryptedValue, process.env.REACT_APP_COOKIE_SECRET);
      const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedValue;
    }
    return null;
  }

  // Funzione per controllare se i Cookie sono impostati
  const checkIfCookieExists = (cookieName) => {
    const cookieValue = Cookies.get(cookieName);
    return !!cookieValue; // Ritorna true se il cookie esiste, false altrimenti
  };

  // Esegui il controllo una sola volta quando la pagina viene montata
  useEffect(() => {
    // Controlla se i cookie relativi all'utente esistono
    const isLoggedIn = checkIfCookieExists(process.env.REACT_APP_COOKIE_EMAIL);
    if (isLoggedIn) {
      // Se l'utente è loggato, invoca il dispatch di login e redirigi alla dashboard
      dispatch(login());
      navigate('/dashboard');
    }
  }, [dispatch, navigate]);

  // Gestione dei campi del form
  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

    //Funzione recovery pw
    const handleRecovery = async () => {
      navigate('/recovery');
    }

  // Funzione per il login
  const handleLogin = async () => {
    if (!username && !password) {
      setextRedAlert('Attenzione! I campi non possono essere vuoti, si prega di riprovare.');
      setloadingAlertRed(true);
      setTimeout(() => {
        setloadingAlertRed(false);
        setextRedAlert('');
      }, 2000);
    } else {
      try {
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_URL_API}/users/login`,
          headers: { 
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          data: {
            'username': username,
            'password': password,
          }
        };

        const response = await axios.request(config);

        if (response.data.status === true) {
          setextGreenAlert('Accesso effettuato con successo.');
          setloadingAlertGreen(true);

          setTimeout(() => {
            setloadingAlertGreen(false);
            setextGreenAlert('');
          }, 2000);

          setloadingSpinner(true);

          setTimeout(() => {
            setloadingSpinner(false);
          }, 2000);

          // Salva i Cookie e gli Crypto nella sessione
          saveEncryptedCookie(process.env.REACT_APP_COOKIE_FNAME, response.data.user.name);
          saveEncryptedCookie(process.env.REACT_APP_COOKIE_LNAME, response.data.user.surname);
          saveEncryptedCookie(process.env.REACT_APP_COOKIE_ROLE, response.data.user.role);
          saveEncryptedCookie(process.env.REACT_APP_COOKIE_EMAIL, response.data.user.email);
          saveEncryptedCookie(process.env.REACT_APP_COOKIE_USERNAME, response.data.user.username);

          

          dispatch(login());
          navigate('/dashboard');

          setUsername('');
          setPassword('');
        } else {
          setextRedAlert('Attenzione! Credenziali errate si prega di riprovare.');
          setloadingAlertRed(true);
          setTimeout(() => {
            setloadingAlertRed(false);
            setextRedAlert('');
          }, 2000);
        }

        return response.data;
      } catch (error) {
        console.error('Error during login:', error);
        throw error;
      }
    }
  };

  return (
    <>
      <div className="flex min-h-screen flex-1">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                alt="Logo Centro del Sorriso di Cuneo"
                src={`${process.env.REACT_APP_URL_FILESERVER}/logo_top.png`}
                className="h-20 w-auto"
              />
              <h2 className="mt-2 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Effettua l'accesso al tuo account
              </h2>
            </div>

            {loadingAlertRed ? <AlertRed testo={textRedAlert} /> : null}
            {loadingAlertGreen ? <AlertGreen testo={textGreenAlert} /> : null}

            <div className="mt-6">
              <div>
                <div className="space-y-4">
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                      Nome Utente
                    </label>
                    <div className="mt-1">
                      <input
                        onChange={handleUsernameChange}
                        value={username}
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                      Password
                    </label>
                    <div className="mt-1 relative">
                      <input
                        onChange={handlePasswordChange}
                        value={password}
                        id="password"
                        name="password"
                        type={showPassword ? "text" : "password"} // Mostra/nasconde la password
                        autoComplete="current-password"
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      <span
                        onClick={togglePasswordVisibility}
                        className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer text-gray-500"
                      >
                        {showPassword ? (
                          <EyeSlashIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        ) : (
                          <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        )}
                      </span>
                    </div>
                  </div>

                  <Listbox value={selected} onChange={setSelected}>
                    <Label className="block text-sm font-medium leading-6 text-gray-900">Lingua</Label>
                    <div className="relative" style={{marginTop: '0.25rem'}}>
                      <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        <span className="flex items-center">
                          <img src={selected.avatar} alt="" className="h-5 w-5 flex-shrink-0 rounded-full" />
                          <span className="ml-3 block truncate">{selected.name}</span>
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                      </ListboxButton>

                      <ListboxOptions className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {lingue.map((person) => (
                          <ListboxOption
                            key={person.id}
                            className={({ active }) =>
                              `${active ? 'bg-indigo-600 text-white' : 'text-gray-900'} relative cursor-default select-none py-2 pl-3 pr-9`
                            }
                            value={person}
                          >
                            {({ selected, active }) => (
                              <>
                                <div className="flex items-center">
                                  <img src={person.avatar} alt="" className="h-5 w-5 flex-shrink-0 rounded-full" />
                                  <span
                                    className={`${selected ? 'font-semibold' : 'font-normal'} ml-3 block truncate`}
                                  >
                                    {person.name}
                                  </span>
                                </div>

                                {selected ? (
                                  <span
                                    className={`${active ? 'text-white' : 'text-indigo-600'} absolute inset-y-0 right-0 flex items-center pr-4`}
                                  >
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </ListboxOption>
                        ))}
                      </ListboxOptions>
                    </div>
                  </Listbox>

                  <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <input
                          id="remember-me"
                          name="remember-me"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                        <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-700">
                          Ricordami
                        </label>
                      </div>
  
                      <div className="text-sm leading-6">
                      <button onClick={() => handleRecovery()} className="font-semibold text-indigo-600 hover:text-indigo-500">
                          Password dimenticata?
                        </button>
                      </div>
                    </div>

                  <div>
                    <button
                      onClick={() => handleLogin()}
                      className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      {loadingSpinner ? (
                        <ThreeCircles visible={true} height="20" width="20" color="#FFFFFF" />
                      ) : null}
                      Accedi
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={`${process.env.REACT_APP_URL_FILESERVER}/logo.png`}
            alt=""
          />
        </div>
      </div>
    </>
  );
}

export default LoginPage;
